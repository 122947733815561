<style scoped>

</style>

<template>
  <div>
    <div class="xrow">
      <CAlert show color="warning">
        Em casos de produtos que possuam descontos e participam de campanha,
        será eleito o melhor desconto entre campanha e política de desconto.
        Para produtos que possuam preço fixo, caso a campanha aplique sobre
        promoção, o desconto da campanha será aplicado em cima do preço
        fixo/promocional. Caso contrário, o desconto da campanha não será
        aplicado.
      </CAlert>
      <div class="col-1-2">
        <div class="form-group">
          <label>Tipo de campanha:</label>
          <select
            class="form-control"
            v-model="item.validacao"
            :disabled="item.erpId && item.id"
          >
            <option value="QT">Quantidade total de itens</option>
            <option value="QD">Quantidade distinta de itens</option>
            <option value="VD">
              Valor mínimo e quantidade distinta de itens
            </option>
            <option value="VQ">Valor mínimo e quantidade total de itens</option>
            <option value="VL">Valor mínimo</option>
            <option value="QQ">Quantidade distinta e total de itens</option>
            <option value="XX">
              Valor mínimo, quantidade distinta e total de itens
            </option>
            <option value="PM">Percentual mínimo</option>
            <option value="PMD">Percentual mínimo por segmento</option>
          </select>
          <div class="message">
            {{ validacao.firstError("item.validacao") }}
          </div>
        </div>
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-1 form-group">
        <small v-if="item.validacao === 'PM'">
          Regra por percentual mínimo utilizando o valor do pedido como
          referência
        </small>
        <small v-if="item.validacao === 'PMD'">
          Regra por percentual mínimo por segmento. Será considerado o valor em
          R$ do segmento da regra de aplicacação para calcular o percentual que
          a regra de validação tem sobre ela.
        </small>
      </div>
    </div>
    <div class="xrow" v-if="!item.validarPorRegra">
      <div class="col-1-1">
        <TipoValidacao
          :item="item"
          :inputShouldBeVisible="inputShouldBeVisible"
          :validacao="validacao"
          :disabled="item.erpId && item.id"
        />
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-3">
        <div class="form-group">
          <label>Tipo de validação das regras:</label>
          <select
            class="form-control"
            v-model="item.tipo"
            :disabled="
              (item.tipo === 'SEGMENTACAO' && item.dimensoes.length > 0) ||
              (item.tipo === 'PERSONALIZADO' && item.dimensoes.length > 0) ||
              (item.tipo === 'MIX' && item.mixProdutos.length > 0) ||
              (item.erpId && item.id)
            "
          >
            <option value="SEGMENTACAO">Segmento</option>
            <option value="PERSONALIZADO"
              :disabled="item.validacao == 'PM' || item.validacao == 'PMD'">
              Combo
            </option>
            <option
              value="MIX"
              :disabled="item.validacao == 'PM' || item.validacao == 'PMD'"
            >
              Mix de Produtos
            </option>
          </select>
          <div class="message">{{ validacao.firstError("item.tipo") }}</div>
        </div>
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-3">
        <div class="form-group">
          <label>
            <span v-if="item.tipo === 'SEGMENTACAO' || item.tipo === 'PERSONALIZADO'"
              >Todas as regras precisam ser válidas?</span
            >
            <span v-if="item.tipo === 'MIX'"
              >Pedido precisa ter todos os produtos?</span>
          </label>
          <select
            class="form-control"
            v-model="item.validarTodasRegras"
            :disabled="item.erpId && item.id"
          >
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="col-1-3" v-if="item.tipo === 'SEGMENTACAO' || item.tipo === 'PERSONALIZADO'">
        <div class="form-group">
          <label>
            <span>Usar validação por regra?</span>
          </label>
          <select
            class="form-control"
            v-model="item.validarPorRegra"
            :disabled="item.erpId && item.id"
          >
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="col-1-3">
        <div class="form-group">
          <label>
            <span>Considera quantidade de estoque?</span>
          </label>
          <select
            class="form-control"
            v-model="item.consideraQtdeEstoque"
            :disabled="item.erpId && item.id"
          >
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="col-1-3">
        <div class="form-group">
          <label>
            <span>Valida sobre promoção?</span>
          </label>
          <select
            class="form-control"
            v-model="item.validaSobrePromocao"
            :disabled="item.erpId && item.id"
          >
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="col-1-3">
        <div class="form-group">
          <label>
            <span>Aplica sobre promoção?</span>
          </label>
          <select
            class="form-control"
            v-model="item.aplicarSobrePromocao"
            :disabled="item.erpId && item.id"
          >
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="col-1-3" v-if="item.tipo === 'SEGMENTACAO' || item.tipo === 'PERSONALIZADO'">
        <div class="form-group">
          <label>
            <span>Acumular descontos?</span>
            <fa-icon icon="info-circle" class="ml-1" :title="tooltipAcumulaDesconto" />
          </label>
          <select
              class="form-control"
              v-model="item.acumulaDesconto"
              :disabled="item.erpId && item.id"
          >
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="col-1-3" v-if="item.tipo === 'SEGMENTACAO' || (item.tipo == 'PERSONALIZADO')">
        <div class="form-group">
          <label>
            <span>Usar validação de não compra?</span>
            <fa-icon icon="info-circle" class="ml-1" :title="tooltipProdutoComprado" />
          </label>
          <select
            class="form-control"
            v-model="item.usaNaoCompra"
            :disabled="item.erpId && item.id"
          >
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
    </div>
    <br>
    <div class="xrow" v-if="item.tipo === 'SEGMENTACAO'">
      <RegraSegmento
        :campanha="item"
        :validacao="validacao"
        :dimensoes="dimensoes"
        :tiposInputs="tiposInputs"
        :showAdicionarRegraButton="true"
      />
    </div>
    <div class="xrow" v-if="item.tipo === 'PERSONALIZADO'">
      <RegraPersonalizada
          :campanha="item"
          :validacao="validacao"
          :dimensoes="dimensoes"
          :tiposInputs="tiposInputs"
      />
    </div>
    <div class="xrow" v-if="item.tipo === 'MIX'">
      <div class="col-1-1">
        <label>
          <strong>Produtos Selecionados:</strong>
          <small class="validation-error">{{
            validacao.firstError("item.mixProdutos")
          }}</small>
        </label>
        <ListaProdutos
          :campanha="item"
          :items="produtos"
          :itemsSelected="item.mixProdutos"
          itemKey="id"
          :select-callback="addProdutoMix"
          :deselect-callback="removeProdutoMix"
          :updatePercentageCallback="updateProdutoMixPercentage"
          :disabled="item.erpId && item.id"
          :loadProdutos="loadProdutos"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListaProdutos from "./ListaProdutos";
import _remove from "lodash/remove";
import TipoValidacao from "./TipoValidacao";
import RegraSegmento from "@/views/Campanhas/components/CampanhasComerciais/components/Regra/RegraSegmento.vue";
import RegraPersonalizada from "@/views/Campanhas/components/CampanhasComerciais/components/Regra/RegraPersonalizada/RegraPersonalizada.vue";
import { resetValidacaoPorTipo } from "../../../../../../helpers/campanhas";

export default {
  name: "Regra",
  props: ["item", "dimensoes", "produtos", "validacao", "loadProdutos"],
  data() {
    return {
      percDescDimensao: null,
      percDescProduto: null,
      tiposInputs: {
        QT: ["qtdeMin", "qtdeMax"],
        QD: ["qtdeMinDistinta", "qtdeMaxDistinta"],
        VD: ["vlMin", "vlMax", "qtdeMinDistinta", "qtdeMaxDistinta"],
        VQ: ["vlMin", "vlMax", "qtdeMin", "qtdeMax"],
        VL: ["vlMin", "vlMax"],
        QQ: ["qtdeMin", "qtdeMax", "qtdeMinDistinta", "qtdeMaxDistinta"],
        XX: [
          "vlMin",
          "vlMax",
          "qtdeMinDistinta",
          "qtdeMaxDistinta",
          "qtdeMin",
          "qtdeMax",
        ],
        PM: ["percMin", "percMax"],
        PMD: ["percMin", "percMax"],
      },
    };
  },
  components: {
    RegraPersonalizada,
    RegraSegmento,
    TipoValidacao,
    ListaProdutos,
  },
  watch: {
    "item.tipo"() {
      resetValidacaoPorTipo(this.item, this.validacao);
    },
    "item.validacao"(newValue, oldValue) {
      if (this.item.tipo == 'PERSONALIZADO') {
        this.item.dimensoes = [];
      }

      this.item.qtdeMin = 0;
      this.item.qtdeMax = null;
      this.item.qtdeMinDistinta = 0;
      this.item.qtdeMaxDistinta = null;
      this.item.vlMin = 0;
      this.item.vlMax = 0;
      this.item.percMin = 0;
      this.item.percMax = 0;
      this.item.tipo = "SEGMENTACAO";
      this.item.mixProdutos = [];
    },
    "item.validarPorRegra"(oldValue, newValue) {
      this.item.qtdeMin = 0;
      this.item.qtdeMax = null;
      this.item.qtdeMinDistinta = 0;
      this.item.qtdeMaxDistinta = null;
      this.item.vlMin = 0;
      this.item.vlMax = 0;
      this.item.percMin = 0;
      this.item.percMax = 0;

      this.item.dimensoes.map((dimensao) => {
        dimensao.qtdeMin = 0;
        dimensao.qtdeMax = null;
        dimensao.qtdeMinDistinta = 0;
        dimensao.qtdeMaxDistinta = null;
        dimensao.vlMin = 0;
        dimensao.vlMax = 0;
        dimensao.percMin = 0;
        dimensao.percMax = 0;
      });
    },
  },
  computed: {
    tooltipAcumulaDesconto() {
      return 'Se essa opção estiver ativada, o percentual de desconto de cada regra válida será somado. ' +
          'Esse desconto acumulado será aplicado a todas as regras de aplicação que forem válidas.';
    },
    tooltipProdutoComprado() {
      return 'Ativar este parâmetro permite personalizar as regras de segmentação, possibilitando filtrar produtos da regra que nunca foram comprados ou que não foram adquiridos em um período específico.';
    }
  },
  methods: {
    updateProdutoMixPercentage(index, newPercentage) {
      if (
        Array.isArray(this.item.mixProdutos) &&
        this.item.mixProdutos[index]
      ) {
        this.item.mixProdutos[index].percDesc = newPercentage;
      }
    },
    addProdutoMix(item) {
      this.item.mixProdutos.push(item);
    },
    removeProdutoMix(id) {
      const produtos = [...this.item.mixProdutos];
      _remove(produtos, (n) => +n.id === +id);
      this.item.mixProdutos = produtos;
    },
    inputShouldBeVisible(inputName) {
      return (
        this.tiposInputs[this.item.validacao] &&
        this.tiposInputs[this.item.validacao].indexOf(inputName) >= 0
      );
    },
  },
};
</script>
