<template>
  <modal
    :name="modalName"
    height="auto"
    width="900"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab
            :title="
              isEdit ? `Editando ${descSingular}` : `Criando ${descSingular}`
            "
          >
            <div class="xrow">
              <div class="col-1-3">
                <div class="form-group">
                  <label for>Nome:</label>
                  <input
                    class="form-control"
                    maxlength="250"
                    v-model="itemData.nome"
                  />
                  <div class="message">
                    {{ validation.firstError("itemData.nome") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for>Data de Início:</label>
                  <datetime
                    input-class="form-control"
                    type="datetime"
                    v-model="itemData.dataInicio"
                    :max-datetime="itemData.dataFim"
                    :phrases="{ ok: 'Confirmar', cancel: 'Fechar' }"
                    value-zone="local"
                  />
                  <div class="message">
                    {{ validation.firstError("itemData.dataInicio") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for>Data de Término:</label>
                  <datetime
                    :disabled="itemData.dataInicio === null"
                    input-class="form-control"
                    type="datetime"
                    :min-datetime="itemData.dataInicio"
                    v-model="itemData.dataFim"
                    :phrases="{ ok: 'Confirmar', cancel: 'Fechar' }"
                    value-zone="local"
                  />
                  <div class="message">
                    {{ validation.firstError("itemData.dataFim") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for>Tipo de Apuração:</label>
                  <select class="form-control" v-model="itemData.tipoApuracao">
                    <option value="P">Pedido</option>
                    <option value="N">Nota Fiscal</option>
                  </select>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label for>Tipo:</label>
                  <select
                    class="form-control"
                    v-model="itemData.tipoCampanha"
                    :disabled="
                      (itemData.tipoCampanha === 'D' &&
                        itemData.dimensoes &&
                        itemData.dimensoes.length > 0) ||
                      (itemData.tipoCampanha === 'M' &&
                        itemData.mix &&
                        itemData.mix.length > 0)
                    "
                  >
                    <option value="D">Dimensão</option>
                    <option value="M">Mix de Produtos</option>
                  </select>
                </div>
              </div>
            </div>
            <hr />
            <div class="xrow" v-if="itemData.tipoCampanha === 'D'">
              <h2>
                DIMENSÕES SELECIONADAS:
                <small class="validation-error">{{
                  validation.firstError("itemData.dimensoes")
                }}</small>
              </h2>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Descrição</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="itemData.dimensoes && !itemData.dimensoes.length">
                      <td colspan="3" style="text-align: center">
                        Adicionar Dimensao
                      </td>
                    </tr>
                    <tr v-for="(dim, index) in dimensoesValidas">
                      <td>{{ index + 1 }}</td>
                      <td>{{ getDescricaoDimensao(dim) }}</td>
                      <td>
                        <button
                          class="button button-error"
                          title="Remover"
                          v-on:click="() => removeDimensao(index)"
                        >
                          <strong>&times;</strong>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h2>ADICIONAR DIMENSÃO:</h2>
              <div class="col-1-4">
                <div class="form-group">
                  <label>Quando:</label>
                  <select
                    class="form-control"
                    v-model="dimensaoForm.indice"
                    v-on:change="changeDimensao"
                  >
                    <option value="-1" v-if="!dimensao.dimensao">
                      Selecione
                    </option>
                    <option v-for="(dim, index) in dimensoes" :value="index">
                      {{ dim.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label>For:</label>
                  <div class="input-group">
                    <select class="form-control" v-model="dimensao.dimensao">
                      <option
                        v-for="(itemData, index) in getDimensoesItens"
                        :key="index"
                        :value="itemData.id"
                        :selected="index === 0"
                      >
                        {{ itemData.desc }}
                      </option>
                    </select>
                    <button
                      class="button button-primary input-group-addon-right"
                      :disabled="dimensaoForm.indice < 0"
                      title="Adicionar"
                      v-on:click="addDimensao"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="xrow" v-else>
              <h2>
                PRODUTOS SELECIONADOS:
                <small class="validation-error">{{
                  validation.firstError("itemData.mix")
                }}</small>
              </h2>
              <Multiselectable
                :items="produtos"
                :campanha="itemData"
                :itemsSelected="itemData.mix"
                itemKey="id"
                :select-callback="addProdutoMix"
                :deselect-callback="removeProdutoMix"
                :loadProdutos="loadProdutos"
              />
            </div>
            <hr />
            <div class="xrow">
              <h2>
                OPERADORES x METAS:
                <small class="validation-error">{{
                  validation.firstError("itemData.metasOperadores")
                }}</small>
              </h2>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Positivação</th>
                      <th>Percentual de Mix</th>
                      <th>Valor Meta</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="
                        itemData.metasOperadores &&
                        !itemData.metasOperadores.length
                      "
                    >
                      <td colspan="6" style="text-align: center">
                        Adicionar Operadores
                      </td>
                    </tr>
                    <tr
                      v-if="itemData.metasOperadores"
                      v-for="(operador, index) in metasOperadoresValidos"
                    >
                      <td>{{ operador.codOp }}</td>
                      <td>{{ getNomeOperador(operador.codOp) }}</td>
                      <td>{{ toPercent(operador.positivacao) }}</td>
                      <td>{{ toPercent(operador.percMix) }}</td>
                      <td>{{ toMonetary(operador.vlMeta) }}</td>
                      <td>
                        <button
                          class="button button-error"
                          title="Remover"
                          v-on:click="() => removeOperador(index)"
                        >
                          <strong>&times;</strong>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" style="text-align: right">
                        TOTAL: {{ getMetaTotal }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="xrow">
              <h2>ADICIONAR OPERADOR:</h2>
              <div class="col-1-4">
                <div class="form-group">
                  <label for>Operador</label>
                  <select class="form-control" v-model="metaOperador.codOp">
                    <option value>Selecione</option>
                    <option v-for="op in operadoresFiltrados" :value="op.codOp">
                      {{ op.nome }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-1-4">
                <div class="form-group">
                  <label for>Positivação</label>
                  <the-mask
                    :mask="['#%', '##%']"
                    class="form-control"
                    type="text"
                    v-model="metaOperador.positivacao"
                  />
                </div>
              </div>
              <div class="col-1-4">
                <div class="form-group">
                  <label for>Percentual de Mix</label>
                  <the-mask
                    :mask="['#%', '##%']"
                    class="form-control"
                    type="text"
                    v-model="metaOperador.percMix"
                  />
                </div>
              </div>
              <div class="col-1-4">
                <div class="form-group">
                  <label for>Valor Meta:</label>
                  <div class="input-group">
                    <money
                      class="form-control"
                      v-model="metaOperador.vlMeta"
                      v-bind="monetary"
                    />
                    <button
                      class="button button-primary input-group-addon-right"
                      :disabled="!metaOperador.codOp"
                      title="Adicionar"
                      v-on:click="addOperador"
                    >
                      +
                    </button>
                  </div>
                  <div class="message">
                    {{ validation.firstError("itemData.vlMeta") }}
                  </div>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button
        :class="{
          'button button-success': true,
        }"
        :disabled="validation.hasError()"
        @click="handleSubmit"
      >
        {{ isEdit ? "Alterar" : "Inserir" }}
      </button>
      <button class="button" @click="close">Fechar</button>
    </div>
  </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import CloseModal from "@/components/CloseModal.vue";
import Multiselectable from "./multiselectable";
import { toMonetary, toPercent } from "@/helpers/utils";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _remove from "lodash/remove";
import _some from "lodash/some";
import _sumBy from "lodash/sumBy";
import { filtrarValidos } from "../../../../helpers/campanhas";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
    greaterThan: "Valor deve ser maior que {0}",
  },
});

export default {
  name: "CampanhaMetas",
  props: {
    item: Object,
    isEdit: Boolean,
    create: Function,
    update: Function,
    close: Function,
    initialItem: Function,
    initialDimensaoForm: Function,
    initialDimensao: Function,
    initialMetaOperador: Function,
    descSingular: String,
    dimensoes: Array,
    operadores: Array,
    produtos: Array,
    loadProdutos: Function,
  },
  data() {
    return {
      internalItem: this.initialItem(),
      dimensaoForm: this.initialDimensaoForm(),
      dimensao: this.initialDimensao(),
      metaOperador: this.initialMetaOperador(),
      monetary: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        masked: false,
      },
    };
  },
  watch: {
    item: {
      handler(newItem) {
        if (newItem) {
          this.internalItem = newItem;
        }
      },
      immediate: true,
      deep: true,
    },
    itemData: {
      handler(newItem) {
        this.$validate();
      },
      deep: true,
    },
    'itemData.tipoCampanha'(novaCampanha) {
      if (novaCampanha === 'D') {
        this.validation.reset('itemData.mix');
      } else if (novaCampanha === 'M') {
        this.validation.reset('itemData.dimensoes');
      }
      this.$validate(); 
    }
  },
  computed: {
    modalName() {
      return this.isEdit ? "campanhaEdit" : "campanhaCreate";
    },
    itemData() {
      return this.internalItem;
    },
    getMetaTotal() {
      return toMonetary(_sumBy(this.itemData.metasOperadores, "vlMeta"));
    },
    getDimensoesItens() {
      const dimensao = _find(
        this.dimensoes,
        (dim) => dim.coluna === this.dimensao.coluna
      );
      return dimensao ? dimensao.dimensoes : [];
    },
    operadoresFiltrados() {
      return _filter(
        this.operadores,
        (operador) =>
          !_some(this.itemData.metasOperadores, (metaOperador) => metaOperador.codOp === operador.codOp)
      );
    },
    metasOperadoresValidos() {
      const validos = filtrarValidos(
        this.itemData.metasOperadores,
        this.operadores,
        (metaOp, op) => +metaOp.codOp === +op.codOp
      );
      
      this.itemData.metasOperadores = validos;
      return validos;
    },
    dimensoesValidas() {
      const validas = filtrarValidos(
        this.itemData.dimensoes,
        this.dimensoes,
        (dim, col) => {
          const coluna = col.coluna;
          const descricao = _find(col.dimensoes, (dimensanId) => dimensanId.id === dim.dimensao);
          return coluna === dim.coluna && !!descricao?.desc;
        }
      );

      this.itemData.dimensoes = validas;
      return validas;
    }
  },
  validators: {
    "itemData.nome": (v) => Validator.value(v).required(),
    "itemData.metasOperadores": (v) => Validator.value(v).required(),
    "itemData.dataInicio": (v) => Validator.value(v).required(),
    "itemData.dataFim": (v) => Validator.value(v).required(),
    "itemData.dimensoes, itemData.tipoCampanha"(dimensoes, tipoCampanha) {
      if (tipoCampanha === "D") return Validator.value(dimensoes).required();
    },
    "itemData.mix, itemData.tipoCampanha"(mix, tipoCampanha) {
      if (tipoCampanha === "M") return Validator.value(mix).required();
    },
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (!success) return;

        if (this.isEdit) {
          this.update(this.itemData);
        } else {
          this.create(this.itemData);
          this.internalItem = initialItem();
        }

        Validator.reset();
      });
    },
    getNomeOperador(codOp) {
      const op = _find(this.operadores, (o) => +o.codOp === +codOp);
      return op?.nome || "—";
    },
    getDescricaoDimensao(dim) {
      const col = this.dimensoes[dim.coluna - 1];
      const desc = _find(col?.dimensoes, (d) => d.id === dim.dimensao);
      
      if (!col || !desc?.desc) {
        return null
      };
      
      return `${col.label} = ${desc.desc}`;
    },
    changeDimensao() {
      this.dimensao = {
        coluna: this.dimensoes[this.dimensaoForm.indice].coluna,
        dimensao: this.dimensoes[this.dimensaoForm.indice].dimensoes[0].id,
      };
    },
    addOperador() {
      this.itemData.metasOperadores.push(this.metaOperador);
      this.metaOperador = {
        codOp: "",
        vlMeta: "1",
        positivacao: "1",
        percMix: "1",
      };
    },
    removeOperador(i) {
      this.itemData.metasOperadores.splice(i, 1);
    },
    addDimensao() {
      this.itemData.dimensoes.push(this.dimensao);
      this.dimensao = { coluna: "", dimensao: "" };
      this.dimensaoForm = { indice: -1 };
    },
    removeDimensao(i) {
      this.itemData.dimensoes.splice(i, 1);
    },
    addProdutoMix(prod) {
      this.itemData.mix.push(prod);
    },
    removeProdutoMix(id) {
      _remove(this.itemData.mix, (p) => +p === +id);
    },
    toMonetary,
    toPercent,
  },
  components: {
    Multiselectable,
    CloseModal,
  },
};
</script>
