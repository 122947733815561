<template>
    <div>
        <div class="table-container">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Produto</th>
                        <th>Percentual Desconto</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!itemsSelected.length">
                        <td colspan="4" style="text-align: center;">Adicionar Produtos</td>
                    </tr>
                    <tr v-for="(i, index) in itemsSelected">
                        <td>{{ i.idExibicao || i.id }}</td>
                        <td>{{ i.descComp || i.desc }}</td>
                        <td>
                            <input-percent v-model="i.percDesc" style="width: 150px; text-align: right;"></input-percent>
                        </td>
                        <td>
                            <button
                              class="button button-error"
                              title="Remover"
                              v-on:click="()=>deselectItem(i, index)"
                              :disabled="campanha.erpId"
                            >
                                <strong>&times;</strong>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <label>
            <strong>Selecione os produtos na lista abaixo:</strong>
        </label>
        <div class="search-select">
            <input
              class="form-control"
              placeholder="Pesquisar por ID, descrição ou referência"
              type="text"
              v-model="searchQuery"
              @input="debouncedSearch"
              :disabled="campanha.erpId && campanha.id"
            />
            <ul class="list">
                <li class="list-item" v-for="item in itemsDisponiveis" :class="{ 'item-disabled': item.disabled }" v-on:click="() => selectItem(item)">
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-right: 50px;">
                        <span>
                            {{ item.idExibicao || item.id }} - {{ truncateText(item.descComp, 80) || truncateText(item.descNF, 80) }}
                        </span>
                    </div>
                    <button class="button button-primary add-item" title="Adicionar" :disabled="item.disabled && item.listType == 'regras'">
                      &nbsp;+&nbsp;
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>

.list-item {
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 50px;
}

.list-item span {
  flex-grow: 1;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.add-item {
  padding: 1px 5px;
}

.table-container {
  max-height: 230px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.table tbody > tr:nth-child(even) td {
  background-color: #fff;
}

.table tbody > tr:nth-child(odd) td {
  background-color: #f1f1f1;
}

.table tbody > tr > td {
  padding: 6px;
}

.list {
  padding: 10px 0;
  height: 200px;
  overflow-y: auto;
}

.list-item {
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  position: relative;
}

.list-item:hover {
  background-color: #ddd !important;
}

.list-item:nth-child(odd) {
  background-color: #f1f1f1;
}

.list-item:nth-child(even) {
  background-color: #fff;
}

.input-desconto {
  width: 100px;
  border: 1px solid #999999;
  border-radius: 4px;
  text-align: right;
}

.list-item.item-disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>

<script>
import _filter from 'lodash/filter';
import _some from 'lodash/some';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import { get } from "@/helpers/apiRequest";

export default {
  name: 'multiselectable',
  props: [
    'campanha',
    'items',
    'itemsSelected',
    'selectCallback',
    'deselectCallback',
    'itemKey',
    'loadProdutos'
  ],
  data() {
    return {
      debouncedSearch: _debounce(() => {
          this.isLoading = true;
          this.searchItems(this.searchQuery);
      }, 500),
      searchQuery: '',
      selectedItems: [],
    };
  },
  computed: {
    itemsDisponiveis() {
      this.items.forEach(produto => {
        produto.disabled = this.itemsSelected.some(selectedItem => selectedItem.id === produto.id);
        produto.listType = 'regras'
      });

      return this.items
    },
  },
  methods: {
    truncateText(text = '', length = 80) {
      const strText = String(text);
      return strText.length <= length ? strText : strText.substring(0, length) + '...';
    },
    searchItems(term, take = 36) {
      const termo = term.target._value ?? '';
      this.loadProdutos(termo, take)
    },
    selectItem(item) {
      const newItem = {
        id: item.id,
        idExibicao: item.idExibicao,
        desc: item.descComp,
        qtde: item.qtde || 1,
        percDesc: item.percDesc || this.campanha.percDesc,
      };

      this.$set(item, 'disabled', true);

      this.selectedItems.push(item);
      this.selectCallback(newItem);
    },
    deselectItem(item, index) {
      this.selectedItems.splice(index, 1);
      this.$set(item, 'disabled', false);
      this.deselectCallback(item[this.itemKey]);
    },
  },
};
</script>
