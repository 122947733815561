import moment from "moment";

export function defaultCampanhaMetasItem() {
  return {
    id: null,
    nome: "",
    tipoCampanha: "D",
    tipoApuracao: "P",
    metaTotal: 0,
    total: 0,
    image: null,
    dataInicio: moment().format(),
    dataFim: moment().add(1, "days").format(),
    dataApuracao: null,
    status: "Não apurada",
    fechado: false,
    metasOperadores: [],
    mix: [],
    dimensoes: [],
  };
}

export function filtrarValidos(itemArray, referencia, compareFn) {
  const validos = itemArray.filter((item) =>
    referencia.some((ref) => compareFn(item, ref))
  );

  if (validos.length !== itemArray.length) {
    return validos;
  }

  return itemArray;
}

export function resetValidacaoPorTipo(item, validacao) {
  if (item.tipo === "MIX") {
    validacao.reset("item.dimensoes");
  } else {
    validacao.reset("item.mixProdutos");
  }
}