<template>
    <div class="xrow">
        <div class="table-container">
            <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Produto</th>
                        <th>Quantidade</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!itemsSelected || !itemsSelected.length">
                        <td colspan="4" style="text-align: center;">Adicionar Brindes</td>
                    </tr>
                    <tr v-for="(item, index) in itemsSelected" :key="item[itemKey] || index">
                        <td>{{ item.idExibicao || item.id }}</td>
                        <td>{{ item.desc }}</td>
                        <td>
                            <input
                              class="form-control"
                              :value="item.qtde"
                              @input="updateQuantity($event, index)"
                              type="number"
                              min="1" style="width: 150px;"
                              :disabled="campanha.erpId && campanha.id"
                            />
                        </td>
                        <td>
                            <button
                              class="button button-error"
                              title="Remover"
                              v-on:click="() => deselectItem(item, index)"
                              :disabled="campanha.erpId && campanha.id"
                            >
                              <strong>&times;</strong>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h2>ADICIONAR BRINDE:</h2>
        <div class="search-select">
          <input
            class="form-control"
            placeholder="Pesquisar por ID, descrição ou referência"
            type="text"
            v-model="searchBrinde"
            @input="debounceBrindes"
          />
          <ul class="list">
              <li class="list-item" v-for="item in brindesDisponiveisFormatados" :key="item.id" v-on:click="() => selectItem(item)" :class="{ 'item-disabled': item.disabled }">
                  <div style="display: flex; align-items: center; justify-content: space-between; margin-right: 50px;">
                    <span>
                        {{ item.idExibicao || item.id }} - {{ truncateText(item.descComp, 80) }}
                    </span>
                  </div>

                  <button
                    class="button button-primary add-item"
                    title="Adicionar"
                    :disabled="campanha.erpId && campanha.id || item.disabled"
                    v-if="!item.disabled" >
                      &nbsp; + &nbsp;
                  </button>
              </li>
               <li v-if="isLoadingBrindes">Carregando...</li>
               <li v-if="!isLoadingBrindes && availableBrindes.length === 0 && searchBrinde">Nenhum brinde encontrado.</li>
          </ul>
        </div>
    </div>
</template>

<style scoped>
.table-container {
  max-height: 230px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.table tbody > tr:nth-child(even) td {
  background-color: #fff;
}

.table tbody > tr:nth-child(odd) td {
  background-color: #f1f1f1;
}

.table tbody > tr > td {
  padding: 6px;
}

.list {
  padding: 10px 0;
  height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
}

.list-item {
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #eee;
}
.list-item:last-child {
    border-bottom: none;
}

.list-item:hover {
  background-color: #ddd !important;
}

.list-item:nth-child(odd) {
  background-color: #f9f9f9;
}

.list-item:nth-child(even) {
  background-color: #fff;
}

.add-item {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  padding: 1px 5px;
}

.input-desconto {
  width: 100px;
  border: 1px solid #999999;
  border-radius: 4px;
  text-align: right;
}

.list-item.item-disabled {
  opacity: 0.6;
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.list-item.item-disabled:hover {
    background-color: #f0f0f0 !important;
}


td input[type=number]:disabled {
    background-color: #eee;
    cursor: not-allowed;
}

td button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
</style>

<script>
import _filter from 'lodash/filter';
import _some from 'lodash/some';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import { get } from "@/helpers/apiRequest";

export default {
  name: 'ListaBrindes',
  props: [
    'campanha',
    'itemsSelected',
    'selectCallback',
    'deselectCallback',
    'itemKey',
    'updateQuantityCallback'
  ],
  data() {
    return {
      debounceBrindes: _debounce(() => this.searchItems(this.searchBrinde), 500),
      searchBrinde: '',
      availableBrindes: [],
      isLoadingBrindes: false,
    };
  },
  computed: {
    brindesDisponiveisFormatados() {
      return this.availableBrindes.map(brinde => {
        const isDisabled = this.itemsSelected && this.itemsSelected.some(
            selectedItem => selectedItem[this.itemKey] === brinde[this.itemKey]
        );
        return {
          ...brinde,
          disabled: isDisabled
        };
      });
    },
  },
  methods: {
    truncateText(text = '', length = 80) {
      if (text.length <= length) {
        return text;
      }
      return text.substring(0, length) + '...';
    },

    searchItems(term = '', take = 36) {
       this.isLoadingBrindes = true;

      get(`/admin/campanhas-produtos-paginados?termo=${encodeURIComponent(term)}&take=${take}`)
        .then((json) => {
          this.availableBrindes = json.itens || []; // Garante que seja um array
        })
        .catch((err) => {
          console.error("Erro ao buscar brindes:", err);
          this.availableBrindes = [];
        })
        .finally(() => {
           this.isLoadingBrindes = false;
        });
    },

    selectItem(item) {
       if (item.disabled) {
           console.warn("Item já selecionado ou desabilitado.");
           return;
       }

      const newItem = {
        id: item.id,
        idExibicao: item.idExibicao,
        desc: item.descComp,
        qtde: item.qtde || 1,
      };

      if (this.selectCallback) {
        this.selectCallback(newItem);
      } else {
          console.warn("selectCallback não foi fornecido como prop.");
      }
    },
    deselectItem(item, index) {

        if (this.deselectCallback) {
            this.deselectCallback(item[this.itemKey]);
        } else {
            console.warn("deselectCallback não foi fornecido como prop.");
        }
    },
    updateQuantity(event, index) {
        const newQuantity = parseInt(event.target.value, 10) || 1;       
        if(this.updateQuantityCallback) {           
            this.updateQuantityCallback(index, newQuantity);
        } else {
             console.warn("updateQuantityCallback não foi fornecido como prop.");
        }
    },
    reloadItems() {
      this.searchItems('');
    },
  },
  created() {
    this.reloadItems();
  },
};
</script>
