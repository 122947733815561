<template>
  <div class="xrow">
    <h2>Brindes Selecionados:</h2>
    <ListaBrindes
      itemKey="id"
      :campanha="item"
      :itemsSelected="item.brindes"
      :select-callback="addBrinde"
      :deselect-callback="removeBrinde"
      :update-quantity-callback="updateBrindeQuantity"
    />
  </div>
</template>

<script>
import ListaBrindes from "./ListaBrindes";
import _remove from "lodash/remove";

export default {
  name: "Brinde",
  props: ["item", "produtos"],
  components: {
    ListaBrindes,
  },
  methods: {
    addBrinde(novoBrinde) {
      const existe = this.item.brindes.some((brinde) => brinde.id === novoBrinde.id);
      if (!existe) {
        this.item.brindes.push(novoBrinde);
      } else {
        console.warn(
          `Tentativa de adicionar brinde duplicado: ID ${novoBrinde.id}`
        );
      }
    },
    removeBrinde(idBrinde) {
      let brindesAtuais = [...this.item.brindes];

      _remove(brindesAtuais, (brinde) => +brinde.id === +idBrinde);

      this.item.brindes = brindesAtuais;
    },

    updateBrindeQuantity(index, newQuantity) {
      if (index < 0 || index >= this.item.brindes.length) {
        console.error(
          "Índice inválido ao tentar atualizar quantidade do brinde:",
          index
        );
        return;
      }

      // Criar cópias para evitar mutação direta e garantir reatividade
      const brindesAtuais = [...this.item.brindes];
      const brindeParaAtualizar = { ...brindesAtuais[index] };

      brindeParaAtualizar.qtde = Math.max(1, parseInt(newQuantity, 10) || 1);

      brindesAtuais.splice(index, 1, brindeParaAtualizar);

      this.item.brindes = brindesAtuais;

      // // Alternativa Vue 2 (se splice/reatribuição não funcionar):
      // this.$set(this.item.brindes[index], 'qtde', Math.max(1, parseInt(newQuantity, 10) || 1));
    },
  },
};
</script>

<style scoped>
</style>
